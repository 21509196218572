import React from "react"
import PropTypes from "prop-types"
import ImageMeta from "../ImageMeta"
import { Video } from "cloudinary-react"
import TheaterVideo from "../Theater/TheaterVideo"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

var classNames = require("classnames")

const Hero = ({
  backgroundImage,
  backgroundImageMobile,
  heading,
  blurb,
  sizzle,
  mobileSizzle,
  dimmed,
  video,
}) => {
  let heroClasses = classNames("page-hero", {
    dimmed: dimmed,
  })
  return (
    <div className={heroClasses}>
      <div>
        {backgroundImage ? (
          <>
            <ImageMeta
              publicId={backgroundImage}
              cloudName="nuvolum"
              width="auto"
              responsive
              responsiveUseBreakpoints="true"
              className="hero-image is-hidden-mobile"
            />
            <ImageMeta
              publicId={backgroundImageMobile}
              cloudName="nuvolum"
              width="auto"
              responsive
              responsiveUseBreakpoints="true"
              className="hero-image is-hidden-desktop is-hidden-tablet"
            />
          </>
        ) : video ? (
          <TheaterVideo
            videoUrl={video}
            controls={true}
            playing={true}
            overVideo={true}
            hasWatchVideo={true}
          >
            <SizzleVideo sizzle={sizzle} mobileSizzle={mobileSizzle} />
          </TheaterVideo>
        ) : (
          sizzle && <SizzleVideo sizzle={sizzle} mobileSizzle={mobileSizzle} />
        )}
        <div className="fixed-width">
          <div className="hero-card">
            <h1>{heading}</h1>
            <MarkdownViewer markdown={blurb} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero

function SizzleVideo({ sizzle, mobileSizzle }) {
  return (
    <div className="video-wrapper">
      <Video
        cloudName="nuvolum"
        className={mobileSizzle && "is-hidden-mobile"}
        publicId={sizzle}
        fallbackContent="Your browser does not support HTML5 video tags."
        quality="auto"
        loop={true}
        muted={true}
        playsInline={true}
        autoPlay={true}
      ></Video>
      {mobileSizzle && (
        <Video
          cloudName="nuvolum"
          className="is-hidden-tablet"
          publicId={mobileSizzle}
          fallbackContent="Your browser does not support HTML5 video tags."
          quality="auto"
          loop={true}
          muted={true}
          playsInline={true}
          autoPlay={true}
        ></Video>
      )}
    </div>
  )
}
