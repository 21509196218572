import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MarkdownViewer from '@bit/azheng.joshua-tree.markdown-viewer'
import ImageMeta from '../components/ImageMeta'
import Hero from '../components/PageHero/Hero'
import SeamlessIframe from '../components/SeamlessIframe'
import { telephoneStrip } from '../utils/utils'

const ContactPage = ({ data, path }) => {
  const post = data.allUniquePagesJson.nodes[0]

  const offices = data.officeDetails.nodes[0].offices

  return (
    <Layout path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <div className="columns">
        <div className="column is-3"></div>
        <div className="column is-8 contact-address-container">
          <div className="sticky-scroll">
            <h1>{post.heading}</h1>
            <p>{post.blurb}</p>

            {/* {offices.map((office, i) => {
              return (
                <div className="section" key={i}>
                  <h3>{office.cityName} inquiries</h3>

                  <h4>Phone</h4>
                  <a
                    href={'tel:+1' + telephoneStrip(office.phoneNumber)}
                    title="Give us a call"
                  >
                    {office.phoneNumber}
                  </a>
                  <br />

                  <h4>Email</h4>
                   <a href={'mailto:' + office.email} title="Send us an email">
                    {office.email}
                  </a> 
                </div>
              )
            })} */}
          </div>
        </div>
        <div className="column contact-form-container">
          {/* <MarkdownViewer
            className="form-heading"
            markdown={post.formHeading}
          /> */}
          <SeamlessIframe src="https://secureform.seamlessdocs.com/f/207678dc7c97443b7eea8a9bc2c42a68?embedded=true" />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query contactPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          blurb
          heading
          sizzleId
          sizzleIdMobile
          imageId
          imageIdMobile
          isSizzle
        }
        addressHeading
        formHeading
        heading
        blurb
      }
    }
    officeDetails: allDataJson(filter: { optionsItem: { eq: "office" } }) {
      nodes {
        offices {
          cityName
          cityZip
          googlePlaceLink
          stateAbbr
          streetName
          streetNumber
          phoneNumber
          email
          googleMapiframe
        }
      }
    }
  }
`
